<template>
  <div :class="[$style.recycle, count === 0 ? $style.recycleno : null]" @click="onClick()">
    <a-badge
      :count="count"
      :number-style="{
        backgroundColor: '#1470df',
        color: '#fff',
      }"
      :offset="[22, 12]"
    >
      <i class="ri-delete-bin-4-line" />
      回收站
    </a-badge>
    <bj-modal
      title="回收站"
      :visible="visible"
      :width="1000"
      :body-style="{ height: '500px', 'max-height': '600px', 'overflow-y': 'auto' }"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div :class="$style.modal">
        <div class="clear-btn">
          <BjButton :loading="btnLoading" type="primary" @click="onClear()">全部清空</BjButton>
          <p class="tip">删除的内容会在回收站保留 14 天，之后将被系统彻底删除。</p>
        </div>
        <a-spin v-if="loading" :class="$style.spin" />
        <div v-else>
          <a-table :columns="columns" :data-source="data" :pagination="false" row-key="id">
            <template #cover="item">
              <div :class="$style.box">
                <div :class="$style.cover">
                  <img :src="item.cover.cover" />
                </div>
              </div>
            </template>
          </a-table>
          <div class="pagination">
            <a-pagination
              :current="page"
              show-size-changer
              :show-total="total => `共 ${total} 条`"
              :page-size.sync="pageSize"
              :total="total"
              @showSizeChange="onShowSizeChange"
              @change="onPageChange"
            />
          </div>
        </div>
      </div>
    </bj-modal>
  </div>
</template>

<script>
import { manageService } from '@/service'

const service = new manageService()

export default {
  name: 'contentRecycle',
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
      page: 1,
      pageSize: 20,
      total: 0,
      data: [],
      loading: false,
      btnLoading: false,
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '内容封面',
          scopedSlots: {
            customRender: 'cover',
          },
        },
        {
          title: '内容名称',
          dataIndex: 'title',
          ellipsis: true,
        },
        { title: '添加时间', dataIndex: 'created_at' },
        {
          title: '管理',
          customRender: item => [
            <bj-link type='primary' onClick={() => this.onRecovery(item.id)}>
              恢复
            </bj-link>,
            <a-popconfirm title='确定要彻底删除吗？删除之后就不能再次找回。' onConfirm={() => this.onDelete(item.id)}>
              <bj-link type='danger'>彻底删除</bj-link>
            </a-popconfirm>,
          ],
        },
      ]
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.getList()
      }
    },
  },
  methods: {
    onClick() {
      this.visible = true
    },
    onClear() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let _this = this
      this.$confirm({
        content: '确定要清空全部回收站数据吗？清空之后就不能再次找回。',
        onOk() {
          _this.clear()
        },
      })
    },
    async onRecovery(id) {
      try {
        await service.recycleRecover({
          id: id,
        })
        this.$message.success('恢复成功')
      } catch (e) {
      } finally {
        this.page = 1
        this.getList()
      }
    },
    async onDelete(id) {
      try {
        await service.recycleDelete({
          id: id,
        })
        this.$message.success('删除成功')
      } catch (e) {
      } finally {
        this.page = 1
        this.getList()
      }
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    async clear() {
      try {
        this.btnLoading = true
        await service.recycleClear()
        this.$message.success('清空成功')
        this.$emit('refresh')
      } catch (e) {
      } finally {
        this.btnLoading = false
        this.page = 1
        this.getList()
      }
    },
    async getList() {
      try {
        this.loading = true
        const { data } = await service.recycleList({
          page: this.page,
          page_size: this.pageSize,
        })
        this.total = data.total
        this.data = data.record
        this.$emit('refresh')
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    handleOk() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
    },
  },
}
</script>

<style lang="less" module>
.recycle {
  display: inline-block;
  margin-right: 60px;
  cursor: pointer;

  i {
    position: relative;
    top: 2px;
    color: #000;
    font-size: 20px;
  }
}

.recycleno {
  margin-right: 30px;
}

.modal {
  .box {
    display: flex;
    width: 250px;
  }

  .cover {
    position: relative;
    width: 80px;
    height: 53px;
    text-align: center;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }
  }

  .btn {
    margin-bottom: 20px;

    p {
      margin-bottom: 0;
      line-height: 30px;
    }
  }

  .spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
