<template>
  <div :class="$style.home">
    <BjSearch @refresh="refresh" @init="init">
      <BjInput
        v-model="search.keyword"
        allow-clear
        label="搜索内容"
        placeholder="请输入内容关键词"
        v-bind="layout"
        @pressEnter="refresh()"
      />
      <BjSelect
        v-model="search.content_type"
        v-bind="layout"
        key-field="value"
        label="内容形式"
        placeholder="请选择"
        value-field="value"
        label-field="label"
        show-all
        :options="typeArr"
      />
      <BjSelect
        v-model="search.group_id"
        v-bind="layout"
        key-field="value"
        label="内容分组"
        inner-search
        placeholder="请选择"
        value-field="id"
        label-field="name"
        show-all
        :options="groupData"
      />
      <BjValidationItem label="内容类型" v-bind="layout">
        <bj-cascader
          v-model="search.type_id"
          :options="typeData"
          :field-names="{
            label: 'name',
            value: 'id',
            children: 'children',
          }"
          change-on-select
          expand-trigger="hover"
          placeholder="请选择内容所属类型"
          :allow-clear="false"
          :load-data="onLoadData"
        />
      </BjValidationItem>
      <BjSelect
        v-model="search.source_type"
        v-bind="layout"
        key-field="value"
        label="内容来源"
        placeholder="请选择"
        value-field="value"
        label-field="label"
        show-all
        :options="sourceData"
      />
      <BjSelect
        v-model="search.status"
        v-bind="layout"
        key-field="id"
        label="内容状态"
        placeholder="请选择"
        value-field="id"
        label-field="label"
        show-all
        :options="stateData"
      />
      <BjSelect
        v-model="search.author_id"
        v-bind="layout"
        key-field="id"
        label="作者信息"
        placeholder="全部状态"
        value-field="id"
        show-all
        label-field="real_name"
        :options="authorData"
      />
    </BjSearch>
    <div :class="$style.content">
      <Table :data="data" :loading="loading" @refresh="refresh" @getList="getList" />
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { manageService } from '@/service'
import { sourceData, typeArr } from '@/utils/variable.js'

import Table from './Table.vue'

const service = new manageService()

export default {
  name: 'Home',
  components: {
    Table,
  },
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      search: {
        keyword: null,
        type_id: [''],
        group_id: '',
        content_type: '',
        source_type: '',
        status: '0',
        author_id: '',
      },
      page: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      // 下拉数据
      typeArr,
      data: [],
      groupData: [],
      typeData: [],
      sourceData,
      stateData: [
        { label: '全部状态', id: '0' },
        { label: '未发布', id: '1' },
        { label: '已发布', id: '2' },
      ],
      authorData: [
        {
          id: '',
          real_name: '全部作者',
        },
      ],
    }
  },
  created() {
    if (this.$route.params.author_id) {
      this.search.author_id = this.$route.params.author_id
    }
    this.getList()
    this.getGroup()
    this.getType()
    this.getAuthor()
  },
  methods: {
    async getList() {
      try {
        this.loading = true
        let type = this.search.type_id.length > 1 ? this.search.type_id[this.search.type_id.length - 1] : ''
        let param = { ...this.search, type_id: type }
        const { data } = await service.list({
          page: this.page,
          page_size: this.pageSize,
          ...param,
        })
        this.data = data.record
        this.total = data.total
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getGroup() {
      const { data } = await service.groupList({
        no_page: 1,
      })
      this.groupData = [{ name: '全部类型', id: '' }].concat(data)
    },
    async getType() {
      const { data } = await service.typeList()
      data.map(item => {
        if (item.has_children) {
          item.isLeaf = false
        }
      })
      this.typeData = [{ name: '全部类型', id: '' }].concat(data)
    },
    async getAuthor() {
      try {
        const { data } = await service.getAuthor({
          no_page: 1,
        })
        this.authorData = this.authorData.concat(data)
      } catch (e) {}
    },
    init() {
      this.search = {
        keyword: null,
        type_id: [''],
        group_id: '',
        content_type: '',
        source_type: '',
        status: '0',
        author_id: '',
      }
      this.getList()
    },
    refresh() {
      this.page = 1
      this.getList()
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    async onLoadData(e) {
      const targetOption = e[e.length - 1]
      targetOption.loading = true
      const { data } = await service.typeList({
        parent_id: targetOption.id,
      })
      targetOption.loading = false
      data.map(item => {
        if (item.has_children) {
          item.isLeaf = false
        }
      })
      targetOption.children = data
      this.typeData = [...this.typeData]
    },
  },
}
</script>

<style lang="less" module>
.home {
  :global {
    .ant-cascader-picker {
      width: 100%;
    }
  }

  .content {
    margin-top: 20px;
    padding: 20px;
    background: #fff;
  }
}
</style>
