<template>
  <div :class="$style.table">
    <a-row :class="$style.head">
      <a-col span="12">内容列表</a-col>
      <a-col span="12" class="text-right">
        <contentRecycle
          v-permission="'content.manage-recycle.view'"
          :count="count"
          @refresh="refresh"
        />
        <a-dropdown placement="bottomRight">
          <BjButton v-permission="'content.manage-create'" type="primary"
            >新建内容
            <i class="ri-arrow-down-s-line right" />
          </BjButton>
          <a-menu slot="overlay" class="list-drop">
            <a-menu-item>
              <BjLink :class="$style.drop" @click="onAdd('post')">
                <i class="ri-article-line" />
                新建图文</BjLink
              >
            </a-menu-item>
            <a-menu-item>
              <BjLink :class="$style.drop" @click="onAdd('video')">
                <i class="ri-movie-line" />
                新建视频</BjLink
              >
            </a-menu-item>
            <a-menu-item>
              <BjLink :class="$style.drop" @click="onAdd('audio')">
                <i class="ri-music-line" />
                新建音频</BjLink
              >
            </a-menu-item>
            <a-menu-item>
              <BjLink :class="$style.drop" @click="onAdd('image')">
                <i class="ri-image-line" />
                新建图册</BjLink
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </a-col>
    </a-row>
    <a-table
      :columns="columns"
      :loading="loading"
      :data-source="data"
      :pagination="false"
      row-key="id"
    >
      <template #cover="item">
        <a-popover placement="topLeft" title="详细信息">
          <template slot="content">
            <div :class="$style.popover">
              <div :class="$style.popoverBox">
                <img :src="item.cover.cover" />
                <div :class="$style.type">
                  <i :class="iconType(item.content_type)" />
                </div>
              </div>
              <div class="ml-10" :class="$style.name">
                <p>{{ item.title }}</p>
              </div>
            </div>
            <div :class="$style.popoverNum">
              <div :class="$style.popoverItem">
                <i class="ri-eye-line primary" />
                <span>{{ (item.read_num + 5000) | toThousands }}</span>
              </div>
              <div :class="$style.popoverItem">
                <i class="ri-message-3-line" />
                <span>{{ item.comment_num | toThousands }}</span>
              </div>
              <div :class="$style.popoverItem">
                <i class="ri-thumb-up-line" />
                <span>{{ item.praise_num | toThousands }}</span>
              </div>
              <div :class="$style.popoverItem">
                <i class="ri-heart-line" />
                <span>{{ item.collect_num | toThousands }}</span>
              </div>
            </div>
          </template>
          <div :class="$style.coverBox">
            <div :class="$style.cover">
              <img :src="item.cover.cover" />
              <div :class="$style.type">
                <i :class="iconType(item.content_type)" />
              </div>
            </div>
            <div class="ml-10" :class="$style.name">
              <p class="ellipsis-width3">{{ item.title }}</p>
            </div>
          </div>
        </a-popover>
      </template>
      <!-- <template #record="item">
        <div>
          <span>
            <i class="ri-eye-line primary" />
            {{ item.read_num }}
          </span>
          <span>
            <i class="ri-message-3-line" />
            {{ item.comment_num }}
          </span>
          <span>
            <i class="ri-thumb-up-line" />
            {{ item.praise_num }}
          </span>
          <span>
            <i class="ri-heart-line" />
            {{ item.collect_num }}
          </span>
        </div>
      </template> -->
      <template #status="item">
        <BjTag :type="item.status ? 'primary' : 'default'">{{
          item.status === 1 ? "已发布" : "未发布"
        }}</BjTag>
      </template>
      <template #action="item">
        <bj-link type="primary" @click="onPreview(item)"> 预览 </bj-link>
        <bj-link
          v-permission="'content.manage-edit'"
          type="primary"
          @click="onEdit(item)"
        >
          编辑
        </bj-link>
        <bj-link type="primary" @click="onComment(item)"> 评论 </bj-link>
        <a-dropdown :trigger="['click']" placement="bottomRight">
          <bj-link class="ant-dropdown-link" type="primary">
            更多<i class="ri-arrow-down-s-line more-drop"
          /></bj-link>
          <a-menu slot="overlay" class="list-drop">
            <a-menu-item>
              <bj-link v-permission="'content.manage-edit'" @click="onState(item)">
                修改状态
              </bj-link>
            </a-menu-item>
            <a-menu-item>
              <bj-link v-permission="'content.manage-edit'" @click="onEdit(item)">
                内容分发
              </bj-link>
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm
                title="确定要删除内容吗？删除之后会在回收站保留 14 天之后将被系统彻底删除。"
                placement="topLeft"
                @confirm="onDelete(item)"
              >
                <bj-link v-permission="'content.manage-delete'"> 删除内容 </bj-link>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </a-table>
    <bj-modal
      title="内容状态"
      :visible="visible.state"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <BjForm>
        <BjValidationItem
          label="设置状态"
          rules="required"
          :label-col="layout.labelCol"
          :wrapper-col="layout.wrapperCol"
          label-align="left"
        >
          <a-radio-group v-model="releaseStatus" name="radioGroup">
            <a-radio :value="1"> 已发布 </a-radio>
            <a-radio :value="0"> 未发布 </a-radio>
          </a-radio-group>
        </BjValidationItem>
      </BjForm>
    </bj-modal>
  </div>
</template>

<script>
import { Modal } from "ant-design-vue";

import contentRecycle from "@/components/contentRecycle";
import { commonService, manageService } from "@/service";

const service = new manageService();
const cService = new commonService();

export default {
  name: "Table",
  components: {
    contentRecycle,
  },
  filters: {
    toThousands(num) {
      return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      count: 0,
      visible: {
        state: false,
        author: false,
      },
      releaseStatus: 1,
      id: null,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
    };
  },
  computed: {
    columns() {
      return [
        {
          title: "内容名称",
          width: 300,
          scopedSlots: {
            customRender: "cover",
          },
        },
        { title: "内容分组", dataIndex: "group_name", ellipsis: true },
        {
          title: "内容类型",
          dataIndex: "type_name",
        },
        {
          title: "内容来源",
          dataIndex: "source_type",
          customRender: (value) => {
            switch (value) {
              case 1:
                return "平台自建";
              case 2:
                return "任务平台";
              case 3:
                return "媒体同步";
            }
          },
        },
        {
          title: "添加时间",
          dataIndex: "created_at",
        },
        {
          title: "内容状态",
          scopedSlots: {
            customRender: "status",
          },
        },
        {
          title: "管理",
          width: 200,
          scopedSlots: {
            customRender: "action",
          },
        },
      ];
    },
  },
  created() {
    this.getTotal();
    this.getAuthor();
  },
  methods: {
    iconType(type) {
      switch (type) {
        case "video":
          return "ri-movie-line";
        case "audio":
          return "ri-music-line";
        case "post":
          return "ri-article-line";
        case "image":
          return "ri-image-line";
      }
    },
    async getAuthor() {
      const { data } = await cService.authorCheck();
      this.author = data;
    },
    async getTotal() {
      const { data } = await service.recycleTotal();
      this.count = data;
    },
    onAdd(type) {
      if (!this.author) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let _this = this;
        this.$confirm({
          title: "账号无作者信息",
          content:
            "当前登录管理员账号无作者信息，需要新建作者信息或通过超级管理员进行分配。",
          onOk() {
            _this.$router.push({
              name: "systemInfo",
              query: {
                page: "author",
              },
            });
          },
          okText: "新建作者信息",
          cancelText: "取消",
          onCancel() {
            Modal.destroyAll();
          },
        });
        return;
      }
      switch (type) {
        case "post":
          this.$router.push({
            name: "contentManageAddImage",
            params: {
              id: "add",
            },
          });
          break;
        case "video":
          this.$router.push({
            name: "contentManageAddVideo",
            params: {
              id: "add",
            },
          });
          break;
        case "audio":
          this.$router.push({
            name: "contentManageAddAudio",
            params: {
              id: "add",
            },
          });
          break;
        case "image":
          this.$router.push({
            name: "contentManageAddAtlas",
            params: {
              id: "add",
            },
          });
          break;
      }
    },
    onEdit(item) {
      switch (item.content_type) {
        case "post":
          this.$router.push({
            name: "contentManageAddImage",
            params: {
              id: item.id,
            },
          });
          break;
        case "video":
          this.$router.push({
            name: "contentManageAddVideo",
            params: {
              id: item.id,
            },
          });
          break;
        case "audio":
          this.$router.push({
            name: "contentManageAddAudio",
            params: {
              id: item.id,
            },
          });
          break;
        case "image":
          this.$router.push({
            name: "contentManageAddAtlas",
            params: {
              id: item.id,
            },
          });
          break;
      }
    },
    async onDelete(item) {
      try {
        await service.delete({
          ids: [item.id],
        });
        this.$emit("refresh");
        this.getTotal();
      } catch (e) {}
    },
    refresh() {
      this.$emit("refresh");
      this.getTotal();
    },
    onState(item) {
      this.id = item.id;
      this.releaseStatus = item.status;
      this.visible.state = true;
    },
    async handleOk() {
      try {
        await service.status({
          id: this.id,
          status: this.releaseStatus,
        });
        this.$message.success("修改成功");
        this.visible.state = false;
      } catch (e) {
      } finally {
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$emit("getList");
      }
    },
    handleCancel() {
      this.visible.state = false;
    },
    onComment(item) {
      this.$store.commit("COMMENT", {
        content_id: item.id,
        keyword: item.name,
      });
      this.$router.push({
        name: "contentComment",
      });
    },
    onPreview(item) {
      this.$store.commit("EDITORBACKNAME", this.$route.fullPath);
      this.$router.push({
        name: "frontPreview",
        query: {
          type: "content",
          id: item.id,
        },
      });
    },
  },
};
</script>

<style lang="less" module>
.popover {
  display: flex;
  // align-items: center;
  width: 260px;

  &-box {
    position: relative;
    width: 64px;
    height: 64px;
    overflow: hidden;
    border-radius: 6px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .type {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      color: #fff;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 6px 0 6px 0;
    }
  }

  .name {
    flex: 1;
    color: #000;
    font-size: 14px;

    p {
      margin: 0;
    }
  }
}

.popover-num {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 14px;

  .popover-item {
    width: 60px;
    height: 60px;
    padding-top: 10px;
    text-align: center;
    background: #fafafa;
    border-radius: 6px;

    i {
      color: @primary-color;
      font-size: 17px;
    }

    span {
      display: block;
    }
  }
}

.table {
  height: calc(100% - 100px);
  background: #fff;

  .head {
    height: 32px;
    margin-bottom: 20px;
    color: #000;
    font-size: 16px;
    line-height: 32px;
  }

  .cover-box {
    display: flex;

    .cover {
      position: relative;
      width: 64px;
      height: 64px;
      overflow: hidden;
      border-radius: 6px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .type {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        color: #fff;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 6px 0 6px 0;
      }
    }

    .name {
      flex: 1;
      color: #000;
      font-size: 14px;

      p {
        margin: 0;
      }
    }
  }
}

.drop {
  /* stylelint-disable-next-line no-descending-specificity */
  i {
    margin-right: 5px;
    font-size: 20px;
    vertical-align: -5px;
  }
}
</style>
